import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAnalyticsContext } from 'contexts';
import { bool, func, string } from 'prop-types';
import { Dictionary } from 'shared';
import Hidden from 'ui-kit/atoms/Hidden';
import IconButton from 'ui-kit/atoms/IconButton';
import LabelWithText from 'ui-kit/atoms/LabelWithText';
import UserData from 'ui-kit/molecules/UserData';
import { formatDocNumber } from 'utils';

import { RoundButton, Text } from '@s7/ui-kit';
import { Pencil, WarningLinear } from '@s7/ui-kit/icons';

import cx from './DocumentContainerView.sss';

import { documentType } from '../../data';
import { getDocument } from '../../store/modules/documents';

const TEXTS = {
    name: {
        BIRTH_CERTIFICATE: (
            <Dictionary text="com.profile.documents.docs.name.birth" />
        ),
        INTERNAL_PASSPORT: (
            <Dictionary text="com.profile.documents.docs.name.passport" />
        ),
        INTERNATIONAL_PASSPORT: (
            <Dictionary text="com.profile.documents.docs.name.outpassport" />
        ),
        MILITARY_CARD: (
            <Dictionary text="com.profile.documents.docs.name.military" />
        ),
        OTHER: <Dictionary text="com.profile.documents.docs.name.other" />,
        VISA: <Dictionary text="com.profile.documents.visa.name" />,
    },
    validity: <Dictionary text="com.profile.documents.validity" />,
    yearShort: <Dictionary text="com.units.year.short" />,
};

const RUS_COUNTRY_CODE = 'RU';

const DocumentContainerView = ({ getTestId, id, isArchive, onSetEdit }) => {
    const {
        countryOfIssue,
        expire,
        isExpired,
        number,
        series,
        type,
        willExpireWithinMonth,
    } = useSelector(state => getDocument(id, state));
    const { sendEvent } = useAnalyticsContext();

    const testIds = useMemo(
        () => ({
            editButton: getTestId('editButton'),
            editButtonMobile: getTestId('editButton', { mobile: true }),
            listDocNumber: getTestId('listDocNumber'),
            listDocType: getTestId('listDocType'),
            listExpirationDate: getTestId('listExpirationDate'),
            row: getTestId('row'),
        }),
        [getTestId],
    );

    const isRussian = countryOfIssue === RUS_COUNTRY_CODE;
    const isRussiaBirth = type === documentType.BIRTH_CERTIFICATE && isRussian;
    const isRussianPassport =
        type === documentType.INTERNAL_PASSPORT && isRussian;
    const isVisa = type === documentType.VISA;
    const isMilitaryCard = type === documentType.MILITARY_CARD;

    const textColor = isArchive ? 'N100' : undefined;
    const dateTextColor = useMemo(() => {
        if (isArchive) {
            return textColor;
        }

        return willExpireWithinMonth ? 'R600' : textColor;
    }, [isArchive, textColor, willExpireWithinMonth]);

    const isDateDisplayed =
        expire && !isRussiaBirth && !isRussianPassport && !isMilitaryCard;
    const date = isDateDisplayed && (
        <>
            {expire} {TEXTS.yearShort}
        </>
    );

    const documentNumber = isRussian
        ? formatDocNumber({ number, type })
        : number;

    const handleSetEdit = e => {
        if (onSetEdit) {
            onSetEdit(e);
        }

        sendEvent({
            event: 'edit_info',
            id: isVisa ? 'visas' : 'documents',
            result: 'initial_click',
            step: 'profile_info',
        });
    };

    return (
        <UserData
            buttonContent={
                (!isExpired || isVisa) && (
                    <>
                        <Hidden on="desktop">
                            <IconButton
                                icon="chevron-right"
                                onClick={handleSetEdit}
                                {...testIds.editButtonMobile}
                            />
                        </Hidden>
                        <Hidden on="mobile">
                            <RoundButton
                                direction="right"
                                size="xs"
                                view="b2c"
                                onClick={handleSetEdit}
                                {...testIds.editButton}
                            >
                                <Pencil />
                            </RoundButton>
                        </Hidden>
                    </>
                )
            }
        >
            <LabelWithText
                label={<>{TEXTS.name[type]}:</>}
                labelColor={textColor}
                testId={testIds.listDocType}
            >
                <Text
                    color={textColor}
                    weight="semibold"
                    noWrap
                    {...testIds.listDocNumber}
                >
                    {series} {documentNumber}
                </Text>
            </LabelWithText>

            {isDateDisplayed && (
                <LabelWithText
                    label={TEXTS.validity}
                    labelColor={dateTextColor}
                >
                    <Text
                        as="div"
                        color={dateTextColor}
                        weight="semibold"
                        noWrap
                        {...testIds.listExpirationDate}
                    >
                        {willExpireWithinMonth ? (
                            <>
                                <div className={cx('date')}>
                                    {date}
                                    <div className={cx('icon')}>
                                        <WarningLinear />
                                    </div>
                                </div>
                            </>
                        ) : (
                            date
                        )}
                    </Text>
                </LabelWithText>
            )}
        </UserData>
    );
};

DocumentContainerView.propTypes = {
    getTestId: func,
    id: string,
    isArchive: bool,
    isVisa: bool,
    onSetEdit: func,
};

export default React.memo(DocumentContainerView);
