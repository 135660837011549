import React, { Fragment, useEffect } from 'react';
import { useAnalyticsContext } from 'contexts';
import Dictionary from 'shared/Dictionary';
import type { LabelType } from 'src/shared/types/label';

import { Divider, Modal } from '@s7/ui-kit';

import { ModalItem } from './ModalItem';

import { getOpenModalEvent } from '../lib/get-open-modal-event';

type LabelsModalProps = {
    visible: boolean;
    toggleVisible: () => void;
    labels: LabelType[];
};

export const LabelsModal = ({
    visible,
    toggleVisible,
    labels,
}: LabelsModalProps): JSX.Element => {
    const { sendEvent } = useAnalyticsContext();

    useEffect(() => {
        if (visible) {
            sendEvent(getOpenModalEvent(labels));
        }
    }, [visible, labels, sendEvent]);

    return (
        <Modal
            isOpen={visible}
            title={<Dictionary text="com.label.list.title" />}
            closeOutside
            portal
            onClose={toggleVisible}
        >
            {labels.map((label, index) => (
                <Fragment key={label.name}>
                    <ModalItem label={label} toggleVisible={toggleVisible} />
                    {labels.length - 1 !== index && <Divider />}
                </Fragment>
            ))}
        </Modal>
    );
};
