import type { ReactNode } from 'react';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

export type StyleVariant = 'default' | 'media';

export type StyleVariantContext = {
    variant: StyleVariant;
};

const defaultStyleVariantContext: StyleVariantContext = {
    variant: 'default',
};

export const StyleVariantContext = createContext(defaultStyleVariantContext);

type Props = {
    children: ReactNode;
};

export const StyleVariantContextProvider = ({
    children,
}: Props): JSX.Element => {
    const router = useRouter();
    const [variant, setVariant] = useState<StyleVariant>('default');

    useEffect(() => {
        if (router.pathname.includes('/media')) {
            setVariant('media');
        } else {
            setVariant('default');
        }
    }, [router.pathname]);

    return (
        <StyleVariantContext.Provider value={{ variant }}>
            {children}
        </StyleVariantContext.Provider>
    );
};

export const useStyleVariantContext = (): StyleVariantContext => {
    const context = useContext(StyleVariantContext);

    return context;
};
