import type { Dispatch, SetStateAction } from 'react';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PROFILE_EMPLOYEE_ROLES } from 'consts/profile';
import ProfilePopupLoadable from 'containers/Header/ProfilePopupLoadable';
import Dictionary from 'shared/Dictionary';
import { logout } from 'store/modules/authentication';
import {
    getCompanyName,
    getEmployeeRole,
    getIsShortProfile,
} from 'store/modules/profile';
import { dictKeys, getTestIdGetter } from 'utils/testIds';

import {
    PriorityStatus as PriorityStatusUI,
    ProfileInfo as ProfileInfoUI,
} from '@s7/ui-kit';

import { Notification } from './Notification';
import { PriorityStatus } from './PriorityStatus';

import { useProfileLinks } from '../hooks/use-profile-links';
import { useProfileTexts } from '../hooks/use-profile-texts';

const getTestId = getTestIdGetter(dictKeys.HEADER);

const TEXTS = {
    [PROFILE_EMPLOYEE_ROLES.ADMIN]: (
        <Dictionary text="com.profile.mycompany.position.admin" />
    ),
    [PROFILE_EMPLOYEE_ROLES.PASSENGER]: (
        <Dictionary text="com.profile.mycompany.position.passenger" />
    ),
    [PROFILE_EMPLOYEE_ROLES.TRAVEL_MANAGER]: (
        <Dictionary text="com.profile.mycompany.position.travelManager" />
    ),
    [PROFILE_EMPLOYEE_ROLES.EMPLOYEE]: (
        <Dictionary text="com.profile.mycompany.position.user" />
    ),
};

type Props = {
    setOpenUserInfo: Dispatch<SetStateAction<boolean>>;
};

export const ProfileInfo = ({ setOpenUserInfo }: Props): JSX.Element => {
    const handleCloseProfileInfo = useCallback((): void => {
        setOpenUserInfo(false);
    }, [setOpenUserInfo]);

    const companyName = useSelector(getCompanyName);
    const role = useSelector(getEmployeeRole);
    const isShortProfile = useSelector(getIsShortProfile);
    const profileLinks = useProfileLinks(handleCloseProfileInfo);
    const { headerTitle, optionalText } = useProfileTexts();
    const dispatch = useDispatch();
    const handleLogout = useCallback(() => {
        dispatch(logout());
    }, [dispatch]);

    return (
        <ProfileInfoUI
            optionalText={
                <ProfilePopupLoadable size={20}>
                    {optionalText}
                </ProfilePopupLoadable>
            }
            title={
                (
                    <ProfilePopupLoadable size={28} separate>
                        {headerTitle}
                    </ProfilePopupLoadable>
                ) as unknown as string
            }
            links={profileLinks}
            logoutButton={<Dictionary text="com.header.menu.logout" />}
            logoutButtonProps={getTestId('logout')}
            onClose={handleCloseProfileInfo}
            onExit={handleLogout}
        >
            {companyName && role && (
                <ProfilePopupLoadable size={68}>
                    <PriorityStatusUI
                        link={
                            role === 'ADMIN'
                                ? 'https://b2b.s7.ru/profile'
                                : (undefined as unknown as string)
                        }
                        desc={companyName}
                        theme="b2b_primary"
                        title={TEXTS[role]}
                    />
                </ProfilePopupLoadable>
            )}
            {!isShortProfile && (
                <ProfilePopupLoadable size={68}>
                    <Notification closeProfileInfo={handleCloseProfileInfo}>
                        <PriorityStatus />
                    </Notification>
                </ProfilePopupLoadable>
            )}
            {isShortProfile && (
                <ProfilePopupLoadable size={68}>
                    <PriorityStatusUI
                        title={
                            <Dictionary text="com.header.menu.s7-priority-registration" />
                        }
                        link="/registration/loyalty"
                        theme="b2c_primary"
                    />
                </ProfilePopupLoadable>
            )}
        </ProfileInfoUI>
    );
};
