import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import {
    change as changeLanguage,
    fetch as fetchLanguage,
    getLoaded as getLoadedLanguages,
} from 'store/modules/languages';
import {
    changeLanguage as loyaltyChangeLanguage,
    isLoaded as isLoadedLoyalty,
} from 'store/modules/loyalty';
import {
    fetchRecover,
    getMilesRecoverIsLoadedRequests,
} from 'store/modules/miles';
import { fetchPromo, isLoadedPromo } from 'store/modules/promo';
import {
    fetchAchievements,
    fetchStatistics,
    isLoadedAchievements,
    isLoadedStatistics,
} from 'store/modules/statistics';
import {
    changeLanguage as transactionsChangeLanguage,
    isLoaded as isLoadedTransactions,
} from 'store/modules/transactions';
import {
    changeLanguage as travelsChangeLanguage,
    isLoadedArchive,
    isLoadedBookings,
} from 'store/modules/travels';

export const useChangeLanguage = (): ((lang: string) => void) => {
    const dispatch = useDispatch();
    const router = useRouter();
    const loaded = useSelector(getLoadedLanguages);
    const isTransactionsLoaded = useSelector(isLoadedTransactions);
    const isBookingsLoaded = useSelector(isLoadedBookings);
    const isArchiveLoaded = useSelector(isLoadedArchive);
    const isLoyaltyLoaded = useSelector(isLoadedLoyalty);

    const isStatisticsLoaded = useSelector(isLoadedStatistics);
    const isAchievementsLoaded = useSelector(isLoadedAchievements);

    const isPromoLoaded = useSelector(isLoadedPromo);

    const isMilesRecoverLoaded = useSelector(getMilesRecoverIsLoadedRequests);

    return useCallback(
        (lang: string) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            dispatch(changeLanguage(lang));

            if (router.route === '/public') {
                window.location.href = `/${lang}${window.location.pathname}`;
            }

            if (isTransactionsLoaded) {
                dispatch(transactionsChangeLanguage());
            }

            if (isTransactionsLoaded) {
                dispatch(transactionsChangeLanguage());
            }

            if (isBookingsLoaded || isArchiveLoaded) {
                dispatch(travelsChangeLanguage());
            }

            if (isLoyaltyLoaded) {
                dispatch(loyaltyChangeLanguage());
            }

            if (isStatisticsLoaded) {
                dispatch(fetchStatistics());
            }

            if (isAchievementsLoaded) {
                dispatch(fetchAchievements());
            }

            if (isPromoLoaded) {
                dispatch(fetchPromo());
            }

            if (isMilesRecoverLoaded) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                dispatch(fetchRecover({ isRefresh: true, page: 0, size: 5 }));
            }

            loaded.forEach((config: unknown) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                dispatch(fetchLanguage(config));
            });
        },
        [
            dispatch,
            isArchiveLoaded,
            isBookingsLoaded,
            isLoyaltyLoaded,
            isStatisticsLoaded,
            isTransactionsLoaded,
            isMilesRecoverLoaded,
            isAchievementsLoaded,
            isPromoLoaded,
            loaded,
            router,
        ],
    );
};
