import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { PROFILE_ANCHORS } from 'components/ProfileView/constants';
import { MILES_RECOVER_ID } from 'containers/ActionMiles/ids';
import { useRouter } from 'next/router';
import Dictionary from 'shared/Dictionary';
import { resetScroll } from 'src/shared/lib/windowUtils';
import { getIsShortProfile } from 'store/modules/profile';
import { RULE_SUBSCRIPTIONS } from 'utils/allowFeature';

import type { ProfileInfo as ProfileInfoUI } from '@s7/ui-kit';

type Links = Parameters<typeof ProfileInfoUI>[0]['links'];

export const useProfileLinks = (closeProfileInfo: () => void): Links => {
    const router = useRouter();
    const isShortProfile = useSelector(getIsShortProfile);

    return useMemo(
        () => [
            {
                children: <Dictionary text="com.header.menu.my-profile" />,
                key: 'hl-myprofile',
                onClick: () => {
                    closeProfileInfo();
                    router.push('/').then(resetScroll);
                },
            },
            {
                children: <Dictionary text="com.header.menu.my-miles" />,
                key: 'hl-mymiles',
                onClick: () => {
                    closeProfileInfo();
                    router.push('/miles').then(resetScroll);
                },
            },
            {
                children: <Dictionary text="com.header.menu.my-travels" />,
                key: 'hl-mytravels',
                onClick: () => {
                    closeProfileInfo();
                    router.push('/travels').then(resetScroll);
                },
            },
            ...(isShortProfile
                ? []
                : [
                      {
                          children: (
                              <Dictionary text="com.header.menu.my-travelers" />
                          ),
                          key: 'hl-travelers',
                          onClick: () => {
                              closeProfileInfo();
                              router
                                  .push({
                                      hash: PROFILE_ANCHORS.travelers,
                                      pathname: '/',
                                  })
                                  .then(resetScroll);
                          },
                      },
                  ]),
            ...(RULE_SUBSCRIPTIONS
                ? [
                      {
                          children: (
                              <Dictionary text="com.header.menu.subscriptions" />
                          ),
                          key: 'hl-subscriptions',
                          onClick: () => {
                              closeProfileInfo();
                              router.push('/subscriptions').then(resetScroll);
                          },
                      },
                  ]
                : []),
            {
                children: <Dictionary text="com.header.menu.miles-recovery" />,
                key: 'hl-claimmiles',
                onClick: () => {
                    closeProfileInfo();
                    router.push({
                        pathname: '/miles',
                        query: { action: MILES_RECOVER_ID },
                    });
                },
            },
            {
                children: (
                    <Dictionary text="com.header.menu.customer-support" />
                ),
                href: 'https://www.s7.ru/s7-priority/ffpContactUs.dot',
                key: 'hl-support',
            },
        ],
        [closeProfileInfo, isShortProfile, router],
    );
};
