import type { ReactNode } from 'react';
import React from 'react';
import { useSelector } from 'react-redux';
import Dictionary from 'shared/Dictionary';
import {
    getEmail,
    getIsShortProfile,
    getName,
    getSocialProfileName,
    getSurname,
    getTelephone,
} from 'store/modules/profile';

type ProfileTexts = {
    headerTitle: ReactNode;
    optionalText: ReactNode;
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
const getEnName = (state: unknown): ReactNode => getName(state, 'en');
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
const getEnSurname = (state: unknown): ReactNode => getSurname(state, 'en');

export const useProfileTexts = (): ProfileTexts => {
    const email = useSelector(getEmail);
    const enName = useSelector(getEnName);
    const enSurname = useSelector(getEnSurname);
    const name = useSelector(getName) || enName;
    const surname = useSelector(getSurname) || enSurname;
    const socialAccountName = useSelector(getSocialProfileName);
    const isShortProfile = useSelector(getIsShortProfile);
    const phone = useSelector(getTelephone);
    let headerTitle: ReactNode;

    let optionalText: ReactNode = '';

    if (isShortProfile) {
        if (socialAccountName) {
            headerTitle = socialAccountName;
        }

        switch (true) {
            case Boolean(name && surname && (email || phone)):
                headerTitle = `${name} ${surname}`;
                optionalText = email || phone;
                break;

            case Boolean(email && phone):
                headerTitle = email;
                optionalText = phone;
                break;

            case Boolean(email):
                headerTitle = (
                    <Dictionary text="com.header.menu.your-profile" />
                );
                optionalText = email;
                break;

            case Boolean(phone):
                headerTitle = (
                    <Dictionary text="com.header.menu.your-profile" />
                );
                optionalText = phone;
                break;

            default:
                headerTitle = (
                    <Dictionary text="com.header.menu.your-profile" />
                );
                break;
        }
    } else {
        headerTitle = `${name} ${surname}`;
        optionalText = email;
    }

    return {
        headerTitle,
        optionalText,
    };
};
