import type { SvgIconProps } from '@s7/base';
import { Box } from '@s7/base';
import type { SX } from '@s7/styles';
import { UserLinear } from '@s7/ui-kit/icons';

import { useAvatarStyles } from './styles';

type Props = SvgIconProps & { sx?: SX };

export const Avatar = ({ sx, ...props }: Props): JSX.Element => {
    const [classes] = useAvatarStyles();

    return (
        <Box className={classes.wrapper} sx={sx}>
            <UserLinear {...props} />
        </Box>
    );
};
