import React from 'react';
import type { LabelProps } from 'src/shared/types/label';
import { Label } from 'src/shared/ui/Label';

import { ActionsPlusLinear } from '@s7/icons';

import { useAddLabelStyles } from './styles';

export const AddLabel = ({ className }: LabelProps): JSX.Element => {
    const [classes, cx] = useAddLabelStyles();

    return (
        <Label
            className={cx(classes.addLabel, className)}
            icon={<ActionsPlusLinear color={'neutral.80'} />}
        />
    );
};
