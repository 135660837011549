import { useState } from 'react';
import { useSelector } from 'react-redux';
import { SessionService } from 'services';
import { ProfileInfo } from 'src/entities/profile';
import { useChangeLanguage } from 'src/features/language';
import { LANGUAGES } from 'src/shared/constants';
import S7LogoMobile from 'src/shared/ui/icons/S7LogoMobile.svg';
import { selectLocale } from 'store/modules/languages';

import { HeaderCanary } from '@s7/ui-kit';
// eslint-disable-next-line import/no-unresolved
import type { SvgIconProps } from '@s7/ui-kit/types/components/SvgIcon';

import { WhiteLogo } from './Logo';
import { useMediaHeaderStyles } from './styles';

import { AvatarIcon } from '../AvatarIcon/AvatarIcon';

export const MediaHeader = (): JSX.Element => {
    const [classes] = useMediaHeaderStyles();
    const language = useSelector(selectLocale);
    const onChangeLanguage = useChangeLanguage();
    const [openUserInfo, setOpenUserInfo] = useState(false);
    const isAuth = SessionService.isAuth();

    return (
        <HeaderCanary
            avatar={AvatarIcon}
            classes={classes}
            isUserInfoOpen={openUserInfo}
            language={language}
            languages={LANGUAGES}
            linkHome="/"
            login={!isAuth}
            logoIcon={WhiteLogo as (props: SvgIconProps) => JSX.Element}
            mobileLogoIcon={S7LogoMobile}
            namespace="test"
            navigation={[]}
            ProfileInfo={<ProfileInfo setOpenUserInfo={setOpenUserInfo} />}
            setOpenUserInfo={setOpenUserInfo}
            hideCurrency
            onChangeLanguage={onChangeLanguage}
        />
    );
};
