import type { TabNavigationItemProps } from '@s7/ui';

export enum NavigationStep {
    MAIN = 'main',
    TRIPS = 'trips',
    SEARCH = 'search',
    INFO = 'info',
    PROFILE = 'profile',
    PAGE = 'page',
}

export type TabNavigationItem = Omit<
    TabNavigationItemProps,
    'href' | 'title'
> & {
    value: NavigationStep;
    href:
        | {
              pathname: string;
              query: { [key: string]: string; tab: NavigationStep };
          }
        | string;
};
