import { createSelector } from 'reselect';
import type { LabelType } from 'src/shared/types/label';
import { TinkoffLabel } from 'src/shared/ui/TinkoffLabel';
import { YandexLabel } from 'src/shared/ui/YandexLabel';
import { getProfileTags } from 'store/modules/profile';

const labelsConfig = {
    SUBSCRIPTION_LIGHT_PLUS: {
        link: '/subscriptions',
        name: 'yandex',
        Node: YandexLabel,
        texts: {
            description: 'com.label.yandex.description',
            title: 'com.label.yandex.title',
        },
    },
    TINKOFF: {
        link: 'https://www.s7.ru/ru/s7-tinkoff-card',
        name: 'tinkoff',
        Node: TinkoffLabel,
        texts: {
            description: 'com.label.tinkoff.description',
            title: 'com.label.tinkoff.title',
        },
    },
};

export const getLabels = createSelector(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    [getProfileTags],
    (tags: string[]): LabelType[] =>
        Object.entries(labelsConfig)
            .map(([code, config]) => ({
                ...config,
                active: tags.includes(code),
            }))
            .sort((a, b) => Number(b.active) - Number(a.active)),
);
