import React, { PureComponent } from 'react';
import { bool, func, oneOf, string } from 'prop-types';
import { Dictionary, UserEdit } from 'shared';
import CardUi from 'ui-kit/atoms/Card';
import maestro from 'ui-kit/atoms/Card/icons/maestro.svg';
import mastercard from 'ui-kit/atoms/Card/icons/masterCard.svg';
import mir from 'ui-kit/atoms/Card/icons/mir.svg';
import visa from 'ui-kit/atoms/Card/icons/visa.svg';

import { Link, Textfield } from '@s7/ui-kit';
import { Trash } from '@s7/ui-kit/icons';

import cx from './Card.sss';

const icons = { maestro, mastercard, mir, visa };

const TEXTS = {
    cancel: <Dictionary text="com.action.cancel" />,
    cardHolder: <Dictionary text="com.profile.cards.holder" />,
    change: <Dictionary text="com.action.change" />,
    confirmationCancel: <Dictionary text="com.title.no" />,
    confirmationRemove: <Dictionary text="com.profile.cards.remove.confirm" />,
    confirmationRemoveConfirm: <Dictionary text="com.action.delete.confirm" />,
    date: <Dictionary text="com.profile.cards.date" />,
    deletionFailure: <Dictionary text="com.profile.cards.remove.failure" />,
    deletionPending: (
        <Dictionary text="com.profile.confirmation.delete.pending" />
    ),
    deletionSuccess: <Dictionary text="com.profile.cards.remove.success" />,
    expiration: <Dictionary text="com.cardpayment.form.expiration" />,
    formNumber: <Dictionary text="com.cardpayment.form.number" />,
    main: <Dictionary text="com.profile.cards.main" />,
    save: <Dictionary text="com.action.save" />,
    title: <Dictionary text="com.title.edit" />,
};

const cardTexts = {
    change: TEXTS.change,
    date: TEXTS.date,
    holder: TEXTS.cardHolder,
    main: TEXTS.main,
    number: TEXTS.formNumber,
};

export default class Card extends PureComponent {
    static propTypes = {
        cardholder: string,
        edit: bool,
        expirationDate: string,
        id: string,
        main: bool,
        needConfirmForRemove: bool,
        number: string,
        onChangeConfirmationRemove: func,
        onClose: func,
        onEdit: func,
        onRemove: func,
        removingStatus: oneOf(['pending', 'success', 'failure']),
        type: string,
    };

    static defaultProps = {
        onClose: () => {},
        onEdit: () => {},
        onRemove: () => {},
    };

    handleRemove = () => {
        const { onRemove, id } = this.props;

        onRemove(id);
    };

    handleEdit = () => {
        const { onEdit, id } = this.props;

        onEdit(id);
    };

    handleClose = () => {
        const { onClose, id } = this.props;

        onClose(id);
    };

    handleOpenConfirmedForRemove = () => {
        this.props.onChangeConfirmationRemove({
            id: this.props.id,
            needConfirmForRemove: true,
        });
    };

    handleCloseConfirmedForRemove = () => {
        this.props.onChangeConfirmationRemove({
            id: this.props.id,
            needConfirmForRemove: false,
        });
    };

    getRemovingMessage = () => {
        const { removingStatus } = this.props;

        if (removingStatus === 'pending') {
            return TEXTS.deletionPending;
        }
        if (removingStatus === 'success') {
            return TEXTS.deletionSuccess;
        }
        if (removingStatus === 'failure') {
            return TEXTS.deletionFailure;
        }

        return '';
    };

    renderButtons() {
        return (
            <Link
                as="button"
                icon={<Trash />}
                type="button"
                view="destructive"
                onClick={this.handleOpenConfirmedForRemove}
            >
                <Dictionary text="com.profile.cards.remove" />
            </Link>
        );
    }

    render() {
        const {
            edit,
            number,
            type,
            cardholder,
            expirationDate,
            needConfirmForRemove,
            removingStatus,
        } = this.props;
        const CardIcon = icons[type];

        return (
            <div className={cx('card', { card_edit: edit })}>
                {edit || removingStatus ? (
                    <UserEdit
                        confirmationRemoveConfirmText={
                            TEXTS.confirmationRemoveConfirm
                        }
                        buttons={this.renderButtons()}
                        cancelText={TEXTS.cancel}
                        confirmationRemoveCancelText={TEXTS.confirmationCancel}
                        confirmationRemoveText={TEXTS.confirmationRemove}
                        needConfirmForRemove={needConfirmForRemove}
                        removingMessage={this.getRemovingMessage()}
                        removingStatus={removingStatus}
                        saveText={TEXTS.save}
                        title={TEXTS.title}
                        hideSave
                        onCloseConfirmedRemove={
                            this.handleCloseConfirmedForRemove
                        }
                        onConfirmedRemove={this.handleRemove}
                        onReset={this.handleClose}
                    >
                        <div className={cx('box')}>
                            <div className={cx('number')}>
                                <Textfield
                                    autoComplete="cc-number"
                                    label={TEXTS.formNumber}
                                    name="cardnumber"
                                    value={number}
                                    disabled
                                    readOnly
                                />
                                {type && (
                                    <span className={cx('type')}>
                                        <CardIcon />
                                    </span>
                                )}
                            </div>
                            <div className={cx('name')}>
                                <Textfield
                                    autoComplete="cc-name"
                                    label={TEXTS.cardHolder}
                                    name="ccname"
                                    value={cardholder}
                                    disabled
                                    readOnly
                                />
                            </div>
                            <div className={cx('date')}>
                                <Textfield
                                    autoComplete="cc-exp"
                                    label={TEXTS.expiration}
                                    name="exp-date"
                                    value={expirationDate}
                                    disabled
                                    readOnly
                                />
                            </div>
                            <div className={cx('cvc')}>
                                <Textfield
                                    autoComplete="cc-csc"
                                    label="CVC / CVV"
                                    name="cvc"
                                    type="password"
                                    value="***"
                                    disabled
                                    readOnly
                                />
                            </div>
                        </div>
                    </UserEdit>
                ) : (
                    <CardUi
                        expirationDate={expirationDate}
                        lang={cardTexts}
                        name={cardholder}
                        number={number}
                        type={type}
                        onClick={this.handleEdit}
                    />
                )}
            </div>
        );
    }
}
