import React, { PureComponent } from 'react';
import { bool, func, object, oneOf, string } from 'prop-types';

import { RoundButton, Text } from '@s7/ui-kit';
import { Pencil } from '@s7/ui-kit/icons';

import cx from './Card.sss';
import jcb from './icons/jcb.svg';
import maestro from './icons/maestro.svg';
import mastercard from './icons/masterCard.svg';
import mir from './icons/mir.svg';
import visa from './icons/visa.svg';

import Icon from '../../../components/Icon';
import { formatCardExpiration } from '../../../utils/format';

const icons = { jcb, maestro, mastercard, mir, visa };

export default class Card extends PureComponent {
    static propTypes = {
        expirationDate: string,
        lang: object,
        main: bool,
        name: string,
        number: string,
        onClick: func,
        theme: oneOf(['white', 'green']),
        type: string,
    };

    static defaultProps = {
        onClick: () => {},
    };

    handleClick = () => {
        this.props.onClick();
    };

    render() {
        const { lang, expirationDate, number, name, type, theme, main } =
            this.props;
        const CardIcon = icons[type];

        return (
            <span className={cx('root', { [`theme_${theme}`]: theme })}>
                {type && (
                    <span className={cx('card-image')}>
                        <CardIcon />
                    </span>
                )}

                <span className={cx('top')}>
                    {main && (
                        <span className={cx('main')}>
                            <span className={cx('main-icon')}>
                                <Icon
                                    height={12}
                                    icon="check-circle-filled"
                                    width={12}
                                />
                            </span>
                            {lang.main}
                        </span>
                    )}
                </span>

                <span className={cx('middle')}>
                    <span className={cx('card-number')}>
                        <Text color="N100" size="s">
                            {lang.number}
                        </Text>
                        {number ? (
                            <Text size="xl" weight="semibold">
                                {number}
                            </Text>
                        ) : (
                            <span className={cx('number-space')} />
                        )}
                    </span>
                </span>

                <span className={cx('bottom')}>
                    <span className={cx('bottom-left')}>
                        {name ? (
                            <Text size="s" weight="semibold">
                                {name}
                            </Text>
                        ) : (
                            <span className={cx('name-space')} />
                        )}
                    </span>
                    <span className={cx('bottom-right')}>
                        {expirationDate ? (
                            <Text size="s" weight="semibold">
                                {formatCardExpiration(expirationDate)}
                            </Text>
                        ) : (
                            <span className={cx('date-space')} />
                        )}
                    </span>
                </span>

                <div className={cx('removeWrapper')}>
                    <RoundButton
                        direction="right"
                        size="xs"
                        text={lang.change}
                        view="b2c"
                        onClick={this.handleClick}
                    >
                        <Pencil />
                    </RoundButton>
                </div>

                <button
                    className={cx('removeAction')}
                    onClick={this.handleClick}
                />
            </span>
        );
    }
}
