import React, { PureComponent } from 'react';
import { bool, func, oneOf, string } from 'prop-types';
import { Dictionary } from 'shared';
import Loader from 'ui-kit/atoms/Loader';

import { Button } from '@s7/ui-kit';
import { Camera, Trash, UserLinear } from '@s7/ui-kit/icons';

import cx from './Avatar.sss';
import AvatarEditor from './AvatarEditor';

import { modalIds } from '../../data';
import Icon from '../Icon';
import InformationPopup from '../MilesActionModal';
import Modal from '../Modal';

const IMG_MIME_TYPES = ['image/jpeg', 'image/pjpeg', 'image/png', 'image/gif'];

const IMG_EXTENSIONS = ['.jpg', '.jpeg', '.png', '.gif'];

const ACCEPT = IMG_EXTENSIONS.join(', ');

export default class Avatar extends PureComponent {
    static propTypes = {
        avatarError: string,
        deleteAvatar: func,
        disabled: bool,
        imgURL: string,
        isAvatarDeleting: bool,
        isAvatarSaving: bool,
        isDoctor: bool,
        isPublic: bool,
        name: string,
        resetAvatarError: func,
        saveAvatar: func,
        size: oneOf(['default', 'small']),
    };

    static defaultProps = {
        deleteAvatar: () => {},
        imgURL: '',
        saveAvatar: () => {},
        size: 'default',
    };

    constructor(props) {
        super(props);

        this.state = {
            error: '',
            open: false,
            showDeleteConfirm: false,
        };

        this.editor = React.createRef();
        this.field = React.createRef();

        this.saveActions = [
            <Button theme="outline" block onClick={this.handleClearImage}>
                <Dictionary text="com.action.cancel" />
            </Button>,
            <Button theme="b2c" block onClick={this.handleSave}>
                <Dictionary text="com.action.save" />
            </Button>,
        ];
        this.deleteActions = [
            <Button
                theme="outline"
                block
                onClick={this.handleCloseDeleteConfirm}
            >
                <Dictionary text="com.action.cancel" />
            </Button>,
            <Button theme="destructive" block onClick={this.handleDeleteConfim}>
                <Dictionary text="com.action.delete" />
            </Button>,
        ];
    }

    handleOpenOverlay = () => {
        this.setState({
            open: true,
        });
    };

    handleCloseOverlay = () => {
        this.setState({
            open: false,
        });
    };

    handleStartUpload = () => {
        this.field.current.click();
        this.handleCloseOverlay();
    };

    handleDeleteClick = () => {
        this.handleOpenDeleteConfirm();
        this.handleCloseOverlay();
    };

    handleDeleteConfim = () => {
        this.handleCloseDeleteConfirm();
        this.props.deleteAvatar();
    };

    handleOpenDeleteConfirm = () => {
        this.setState({ showDeleteConfirm: true });
        this.handleCloseOverlay();
    };

    handleCloseDeleteConfirm = () =>
        this.setState({ showDeleteConfirm: false });

    handleChange = event => {
        const file = event.target.files[0];

        if (!file) {
            return;
        }

        // Don't apply image to editor if it's mime type not in IMG_MIME_TYPES list
        // or extension not in IMG_EXTENSIONS list
        if (!IMG_MIME_TYPES.includes(file.type)) {
            const matches = file.name.match(/(\.[^.]+)$/);

            if (
                !matches ||
                !IMG_EXTENSIONS.includes(matches[1].toLowerCase())
            ) {
                this.setState({
                    error: <Dictionary text="com.avatar.error.not-image" />,
                });

                return;
            }
        }

        if (file.size > 10485760) {
            this.setState({
                error: <Dictionary text="com.avatar.error.too-big" />,
            });

            return;
        }

        const img = new Image();

        img.src = window.URL.createObjectURL(file);

        img.onload = () => {
            if (img.width < 200 || img.height < 200) {
                this.setState({
                    error: <Dictionary text="com.avatar.error.too-small" />,
                });
            } else {
                this.setState({ image: file });
            }
        };
    };

    handleClearImage = () => {
        this.setState({ image: null });
        this.field.current.value = '';
    };

    handleFocus = () => {
        if (document.activeElement) {
            document.activeElement.blur();
        }
    };

    handleResetError = () => {
        const { resetAvatarError } = this.props;
        const { error } = this.state;

        if (resetAvatarError) {
            resetAvatarError();
        }
        if (error) {
            this.setState({ error: '' });
        }
    };

    handleSave = () => {
        const { image } = this.state;
        const formData = new FormData();
        const { x, y, width, rotate } = this.editor.current.getData(true);
        const centerOffset = width / 2;

        const centerX = Math.floor(x + centerOffset);
        const centerY = Math.floor(y + centerOffset);

        formData.append('avatar', image);
        formData.append('centerX', `${centerX}`);
        formData.append('centerY', `${centerY}`);
        formData.append('width', `${width}`);
        formData.append('height', `${width}`);
        formData.append('rotation', `${rotate}`);
        this.props.saveAvatar({ formData });
        this.handleClearImage();
    };

    renderOverlay() {
        const { imgURL } = this.props;

        return (
            <div className={cx('mobileOverlay')}>
                {imgURL && (
                    <div className={cx('buttons')}>
                        <button
                            className={cx('button')}
                            onClick={this.handleStartUpload}
                        >
                            <Dictionary text="com.avatar.change-photo" />
                        </button>
                        <button
                            className={cx('button', 'button_red')}
                            onClick={this.handleDeleteClick}
                        >
                            <Dictionary text="com.avatar.delete-photo" />
                        </button>
                    </div>
                )}
                {!imgURL && (
                    <div className={cx('buttons')}>
                        <button
                            className={cx('button')}
                            onClick={this.handleStartUpload}
                        >
                            <Dictionary text="com.avatar.add-photo" />
                        </button>
                    </div>
                )}
                <div className={cx('buttons')}>
                    <button
                        className={cx('button', 'button_bold')}
                        onClick={this.handleCloseOverlay}
                    >
                        <Dictionary text="com.action.cancel" />
                    </button>
                </div>
            </div>
        );
    }

    renderWithImage() {
        const {
            imgURL,
            disabled,
            isAvatarDeleting,
            isAvatarSaving,
            isPublic,
            size,
        } = this.props;

        return (
            <div
                key="withImage"
                className={cx('avatar', {
                    avatar_disabled: disabled,
                    'avatar-default-size': size === 'default',
                    'avatar-small-size': size === 'small',
                })}
            >
                <div
                    className={cx('image')}
                    style={{ backgroundImage: `url(${imgURL})` }}
                />

                {!isPublic && (
                    <>
                        {isAvatarDeleting || isAvatarSaving ? (
                            <div className={cx('loader')}>
                                <Loader />
                            </div>
                        ) : (
                            <>
                                <div
                                    style={{
                                        backgroundImage: `url(${imgURL})`,
                                    }}
                                    className={cx('blurred')}
                                />

                                <div className={cx('overlay')}>
                                    <button
                                        className={cx('button')}
                                        onClick={this.handleStartUpload}
                                        onFocus={this.handleFocus}
                                    >
                                        <Camera size="md" />
                                    </button>
                                    <button
                                        className={cx('button')}
                                        onClick={this.handleDeleteClick}
                                        onFocus={this.handleFocus}
                                    >
                                        <Trash />
                                    </button>
                                </div>

                                <button
                                    className={cx('openOverlay')}
                                    onClick={this.handleOpenOverlay}
                                />
                            </>
                        )}
                    </>
                )}
            </div>
        );
    }

    renderWithoutImage() {
        const { disabled, isAvatarSaving, isPublic, name, size } = this.props;

        return (
            <div
                key="withoutImage"
                className={cx('avatar', {
                    avatar_disabled: disabled,
                    avatar_public: isPublic,
                    'avatar-default-size': size === 'default',
                    'avatar-small-size': size === 'small',
                })}
            >
                {isAvatarSaving ? (
                    <div className={cx('loader')}>
                        <Loader />
                    </div>
                ) : (
                    <>
                        <div className={cx('image', 'image_empty')}>
                            {name ? (
                                <div className={cx('name')}>{name}</div>
                            ) : (
                                <UserLinear height={40} width={40} />
                            )}
                        </div>
                        {!isPublic && (
                            <button
                                className={cx('openOverlay')}
                                onClick={this.handleOpenOverlay}
                            />
                        )}
                        {!isPublic && (
                            <div
                                className={cx(
                                    'overlay',
                                    'overlay--without-image',
                                )}
                            >
                                <button
                                    className={cx('fullsize')}
                                    onClick={this.handleStartUpload}
                                    onFocus={this.handleFocus}
                                >
                                    <Camera size="md" />
                                </button>
                            </div>
                        )}
                    </>
                )}
            </div>
        );
    }

    render() {
        const { imgURL, disabled, avatarError, isDoctor } = this.props;
        const { open, image, showDeleteConfirm, error } = this.state;

        return (
            <div className={cx('root')}>
                {showDeleteConfirm && (
                    <Modal
                        actions={this.deleteActions}
                        classes={{ buttons: cx('modal-buttons') }}
                        id={modalIds.AVATAR_DELETION}
                        size="small"
                        withScroll={false}
                        centered
                        ds
                        withoutHorizontalPaddings
                        onClose={this.handleCloseDeleteConfirm}
                    >
                        <div className={cx('avatar-delete__confirm')}>
                            <Dictionary text="com.avatar.delete.confirm" />
                        </div>
                    </Modal>
                )}

                {imgURL ? this.renderWithImage() : this.renderWithoutImage()}
                {isDoctor && (
                    <>
                        <div className={cx('doctor-icon')}>
                            <Icon
                                height="100%"
                                icon="doctor-on-board"
                                width="100%"
                            />
                        </div>
                        <div className={cx({ 'doctor-outline': isDoctor })} />
                    </>
                )}

                {image && (
                    <Modal
                        contentComponent={() => (
                            <div className={cx('avatar-editor')}>
                                <AvatarEditor ref={this.editor} image={image} />
                            </div>
                        )}
                        actions={this.saveActions}
                        id={modalIds.AVATAR_CONFIRMATION}
                        maxContentHeight={400}
                        widthSize="x-medium"
                        withScroll={false}
                        centered
                        ds
                        onClose={this.handleClearImage}
                    />
                )}
                {(avatarError || error) && (
                    <InformationPopup
                        id={modalIds.AVATAR_ERROR}
                        title={avatarError || error}
                        type="failure"
                        onClose={this.handleResetError}
                    />
                )}
                <input
                    ref={this.field}
                    accept={ACCEPT}
                    className={cx('input')}
                    disabled={disabled}
                    type="file"
                    onChange={this.handleChange}
                    onFocus={this.handleFocus}
                />
                {open && this.renderOverlay()}
            </div>
        );
    }
}
