import React, { PureComponent } from 'react';
import { bool, func, node, object, oneOfType, shape, string } from 'prop-types';

import { StatusMessage, Text } from '@s7/ui-kit';

import cx from './UserData.sss';

import IconButton from '../../atoms/IconButton';

const testIdsShape = shape({
    button: object,
    cyrillicName: object,
    latinName: object,
    root: object,
});

const noop = () => {};

export default class UserData extends PureComponent {
    static propTypes = {
        button: string,
        buttonContent: node,
        children: node,
        flexHeading: bool,
        help: oneOfType([string, node]),
        leftHeading: bool,
        mobileType: string,
        name: node,
        noIndent: bool,
        noMobileAction: bool,
        notification: node,
        onAction: func,
        onCloseNotification: func,
        revertheadingMobile: bool,
        row: bool,
        testIds: testIdsShape,
        traveler: bool,
        type: string,
    };

    static defaultProps = {
        name: '',
        onAction: noop,
        testIds: {
            button: {},
            cyrillicName: {},
            latinName: {},
            root: {},
        },
        type: '',
    };

    onMobileAction = () => {
        if (window.innerWidth <= 767) {
            this.props.onAction();
        }
    };

    render() {
        // eslint-disable-line complexity
        const {
            button,
            buttonContent,
            children,
            flexHeading,
            help,
            leftHeading,
            mobileType,
            name,
            noIndent,
            noMobileAction,
            notification,
            onAction,
            onCloseNotification,
            revertheadingMobile,
            row,
            testIds,
            type,
        } = this.props;

        return (
            <div
                className={cx('UserData', {
                    UserData_flexHeading: flexHeading,
                    UserData_help: help,
                    UserData_leftHeading: leftHeading,
                    UserData_noIndent: noIndent,
                    UserData_noMobileAction: noMobileAction,
                    UserData_revertheadingMobile: revertheadingMobile,
                })}
                {...testIds.root}
            >
                {help && (
                    <div className={cx('help')}>
                        <Text size="l">{help}</Text>
                    </div>
                )}
                {notification && (
                    <div className={cx('notification')}>
                        <StatusMessage
                            view="warning"
                            onClose={onCloseNotification}
                        >
                            {notification}
                        </StatusMessage>
                    </div>
                )}
                <div className={cx('info')}>
                    {name && (
                        <div className={cx('heading')}>
                            <Text as="div" {...testIds.latinName}>
                                {name}
                            </Text>
                            {type && (
                                <div className={cx('type')}>
                                    <Text
                                        as="div"
                                        color="inherit"
                                        {...testIds.cyrillicName}
                                    >
                                        {type}
                                    </Text>
                                </div>
                            )}
                            {(type || mobileType) && (
                                <div className={cx('type_mobile')}>
                                    <Text
                                        color="N100"
                                        {...testIds.cyrillicName}
                                    >
                                        {mobileType || type}
                                    </Text>
                                </div>
                            )}
                        </div>
                    )}
                    <div
                        className={cx('content')}
                        onClick={noMobileAction ? this.onMobileAction : noop}
                    >
                        {React.Children.map(children, child => (
                            <div
                                className={cx('item', {
                                    item_column: !row,
                                    item_row: row,
                                })}
                            >
                                {child}
                            </div>
                        ))}
                    </div>
                </div>
                {(button || buttonContent) && (
                    <div className={cx('action')}>
                        {buttonContent || (
                            <IconButton
                                icon={button}
                                theme="greenDesktopCircle"
                                onClick={onAction}
                                {...testIds.button}
                            />
                        )}
                    </div>
                )}
            </div>
        );
    }
}
